import { ZodAny, z } from 'zod'

export const OdometerGridDataSchema = z.object({
  odometerDueDate: z.coerce.date().optional(),
  effectiveDate: z.coerce.date(),
  userId: z.number(),
  displayPolicyNumber: z.string(),
  userEmail: z.string(),
  odometerStatus: z.string(),
  policyId: z.string(),
  policyBreakdownTransactionId: z.string(),
  commercialDrivenMiles: z.number(),
  isPendingApproval: z.boolean(),
})

export const OdometersGridSchema = z.object({
  objects: z.array(OdometerGridDataSchema),
  meta: z.object({
    total: z.number(),
    offset: z.number(),
    limit: z.number(),
  }),
})

export type OdometersGridApi = z.infer<typeof OdometersGridSchema>
export type OdometerGridDataSchema = z.infer<typeof OdometerGridDataSchema>
