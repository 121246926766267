import { validateOdometersEndpoint } from '@drive/shared'
import { ApiError, getToken, useStore } from '@skywatch/core'
import { OdometerToValidateRequest } from '../store'
import odometerManagerResource from '../store/odometerManager.resource'
const { VITE_VOOM_API_PATH } = import.meta.env

export const useOdometerManager = () => {
  const baseHook = useStore(odometerManagerResource)
  const validateOdometers = async (policyId: string, odometers: OdometerToValidateRequest) => {
    try {
      const { data } = await validateOdometersEndpoint(odometers, {
        pathParams: { policyId: policyId },
      })
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        console.log('error', error)
      }
    }
  }

  const uploadOdometer = async (
    file: File,
    policyId: string,
    transactionItemId: string,
    vehicleId: string,
    miles: number
  ) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('miles', miles.toString())

    const url = `${VITE_VOOM_API_PATH}/policyAction/${policyId}/transactionItem/${transactionItemId}/vehicle/${vehicleId}/AdminUploadOdometer`
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
  }

  return { ...baseHook, validateOdometers, uploadOdometer }
}
