import { createEndpoint } from '@skywatch/core'
import { OdometersGridSchema, PoliciesGridSchema, QuoteGridSchema, UsersSchema } from '~/models'
import { DeclinationSchema } from '~/models/declination'
import { ProfileSchema } from '~/models/profile'

const { VITE_VOOM_API_PATH } = import.meta.env

export const getPoliciesGridDataEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getPolicyGridDataByQuery`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: PoliciesGridSchema,
  },
})

export const getOdometersGridDataEndpoint = createEndpoint(
  `${VITE_VOOM_API_PATH}/dashboard/getOdometerGridDataByQuery`,
  {
    method: 'GET',
    useAuthorization: true,
    validation: {
      responseSchema: OdometersGridSchema,
    },
  }
)

export const getEmailsEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getApplicationEmails`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: UsersSchema,
  },
})

export const getPoliciesEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getPoliciesEmails`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: UsersSchema,
  },
})
export const getQuoteGridDataEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getQuoteData`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: QuoteGridSchema,
  },
})

export const getQuoteReportEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getQuoteReport`, {
  method: 'POST',
  useAuthorization: true,
})