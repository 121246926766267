import { getQuoteGridDataEndpoint } from '~/api'
import { GetQuotesGridQueryRequest, Quote } from './types'
import { Collection, CreateThunksReturn, createCollection, initCollection } from '@skywatch/core'

const fetchQuoteDataGrid = async (quoteGridQueryRequest: GetQuotesGridQueryRequest) => {
  try {
    const { data } = await getQuoteGridDataEndpoint(null, { queryParams: quoteGridQueryRequest })
    const objects = data.objects.map(quote => Quote.fromApi(quote))
    return {
      objects,
      meta: data.meta,
    }
  } catch (e) {
    throw new Error('temp error')
  }
}

export default createCollection({
  name: 'quotes',
  initialState: initCollection<Collection<Quote>>(),
  asyncMethods: (thunks: CreateThunksReturn<Quote>) => ({
    fetchQuoteDataGrid: thunks.upsertMany(fetchQuoteDataGrid),
  }),
})
