import { HTMLAttributes, SVGAttributes, useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wait } from '@drive/shared'
import { useProfile, useSession } from '@drive/auth'
import {
  Page,
  FilePlusIcon,
  SpeedometerIcon,
  useDialog,
  EmailIcon,
  Button,
  FileIcon,
  UserPlusIcon,
  ClockRefreshIcon,
  FileShieldIcon,
  UsersEditIcon,
  UserEditIcon,
  GraphIcon,
  FileEditIcon,
  CloseIcon,
} from '@skywatch/ui'
import { Header, SelectUserApplicationsDialog, SelectUserPoliciesDialog } from '~/components'

type ServiceItem = {
  title: string
  actions: Action[]
  icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
}

type Action = {
  type: ServiceType
  title: string
  key: ActionKey
  to: string
  icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
  openDialog: boolean
}

const IconWrapper = ({ children }: HTMLAttributes<HTMLDivElement>) => (
  <div className="bg-primary-50 box-content w-fit self-center rounded-full p-2.5">
    <div className=" bg-primary-100 box-content rounded-full border-none p-3 shadow shadow-gray-200">{children}</div>
  </div>
)

const items: ServiceItem[] = [
  {
    title: 'General Reports',
    actions: [
      {
        key: 'generateReport',
        title: 'Generate a Report',
        to: '/reports',
        icon: FilePlusIcon,
        openDialog: false,
        type: 'reports',
      },
    ],
    icon: FileIcon,
  },
  {
    title: 'Account Monitoring',
    actions: [
      {
        key: 'recentSubmissions',
        title: 'Recent Submissions',
        to: '/dashboard/quotes',
        icon: ClockRefreshIcon,
        openDialog: false,
        type: 'visitors',
      },
      {
        key: 'policiesManagement',
        title: 'Policies Management',
        to: '/dashboard/policies',
        icon: FileShieldIcon,
        openDialog: false,
        type: 'policies',
      },
      {
        key: 'odometersMonitoring',
        title: 'Odometers Monitoring',
        to: '/dashboard/odometers',
        icon: SpeedometerIcon,
        openDialog: false,
        type: 'policies',
      },
    ],
    icon: GraphIcon,
  },
  {
    title: 'Customer Management',
    actions: [
      {
        key: 'initiateContinueQuote',
        title: 'Initiate / Continue a Quote',
        to: '',
        icon: UserPlusIcon,
        openDialog: true,
        type: 'quote',
      },
      { key: 'manageProfile', title: 'Manage Profile', to: '', icon: UserEditIcon, openDialog: true, type: 'manage' },
      { key: 'managePolicy', title: 'Manage a Policy', to: '', icon: FileEditIcon, openDialog: true, type: 'manage' },
    ],
    icon: UsersEditIcon,
  },
]

type ServiceType = 'quote' | 'manage' | 'policies' | 'visitors' | 'reports' | 'account monitoring'
export type ActionKey =
  | 'generateReport'
  | 'recentSubmissions'
  | 'policiesManagement'
  | 'odometersMonitoring'
  | 'managePolicy'
  | 'initiateContinueQuote'
  | 'manageProfile'

export default () => {
  const navigator = useNavigate()
  const [service, setService] = useState<ServiceType | null>()
  const [Loader, setLoader] = useState(false)
  const { setOpen, Dialog } = useDialog()
  const { state: user } = useSession()
  const { reset } = useProfile()

  const userServices = useMemo(
    () =>
      items
        .map(service => {
          const permittedAction = service.actions.filter(action => user?.permissions.includes(action.key))
          return {
            ...service,
            actions: permittedAction,
          }
        })
        .filter(service => service.actions.length > 0),
    [user?.permissions]
  )

  const handleDialog = (action: Action) => {
    setService(action.type as ServiceType)
    if (action.openDialog) {
      setOpen(true)
    } else {
      navigator(action.to)
    }
  }

  const renderDialogContent = (service?: ServiceType | null) => {
    switch (service) {
      case 'manage':
        return <SelectUserPoliciesDialog title="Manage Insurance Policies" />
      case 'quote':
        return <SelectUserApplicationsDialog title="Quote a Customer" onLoading={setLoader} loading={Loader} />
      case 'policies':
        return <div></div>
      default:
        return <div />
    }
  }

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="font-poppins">
      <Page className="bg-white">
        <Header />
        <div className="mx-auto mt-24 max-w-6xl text-center">
          <div className="mb-6 text-xl">Admin Portal</div>
          <div>
            Hi <span className="capitalize">{user?.firstName},</span>
          </div>
          <div>What would you like to do?</div>
          <div className="ml-auto mr-auto mt-20 flex flex-row flex-wrap content-center justify-center gap-x-8 gap-y-6">
            {userServices.map(item => (
              <div
                key={item.title}
                className="item-center  flex w-72 flex-col items-stretch gap-5 rounded-xl border p-4"
              >
                <IconWrapper className="absolute">
                  <item.icon className="stroke-primary-500 stroke-2" />
                </IconWrapper>
                <div className="font-semibold">{item.title}</div>
                <div className="flex flex-col items-center gap-5 text-xs text-gray-600">
                  {item.actions.map(action => (
                    <div
                      className="btn w-full justify-start rounded-xl bg-gray-50 p-4 text-gray-600 hover:bg-gray-200"
                      key={action.key}
                      onClick={() => handleDialog(action)}
                    >
                      <action.icon className="stroke-black stroke-2" />
                      {action.title}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Dialog>
          <div className="flex justify-between">
            <div className="relative mb-2">
              <IconWrapper className="absolute">
                <EmailIcon className="stroke-primary-500 stroke-2" />
              </IconWrapper>
            </div>
            <div>
              <button onClick={() => setOpen(false)}>
                <CloseIcon className="stroke-gray-500 stroke-2" />
              </button>
            </div>
          </div>
          {renderDialogContent(service)}
          <div className="mt-3 grid grid-cols-6 gap-3">
            <Button buttonType={'gray'} variant={'outline'} className="col-span-3" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button buttonType={'primary'} variant={'standard'} className="col-span-3" type="submit" form="modal">
              Continue
            </Button>
          </div>
        </Dialog>
      </Page>
      <Wait visible={Loader} />
    </div>
  )
}
