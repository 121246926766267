import { HTMLAttributes } from 'react'
import { createPortal } from 'react-dom'

type Props = HTMLAttributes<HTMLDivElement> & {
  message?: string
}
/**
 * @description Component used to display information to the admin within user-bound UI flows
 */
const AdminPrompt = ({ message }: Props) =>
  message
    ? createPortal(
        <div className="bg-success-50 border-success-500 text-success-700 absolute left-1/2 top-16 z-50 -translate-x-1/2 rounded-md border px-2 py-1 text-sm">
          {message}
        </div>,
        document.body
      )
    : null

export default AdminPrompt
