import { ChangeEvent, useEffect, useState } from 'react'
import { z } from 'zod'
import { Form } from '@skywatch/ui'
import { PendingApprovalOdometer } from '../store'

export const MilesFormDto = z.object({
  odometerValue: z.number({ errorMap: () => ({ message: 'Required' }) }),
  tncMilesValue: z.number({ errorMap: () => ({ message: 'Required' }) }),
})

type MilesFormError = {
  odometerValue: string | undefined
  tncMilesValue: string | undefined
}

export type MilesFormType = z.infer<typeof MilesFormDto>

export type MilesFormProps = {
  vehicleOdometer: PendingApprovalOdometer
  userId: number
  vehicleIndex: number
  count: number
  onSubmitForm: (data: MilesFormType) => void
}

const createValidator = (lastVlidationValue: number) => {
  return z
    .object({
      odometerValue: z
        .number({ errorMap: () => ({ message: 'Required' }) })
        .nonnegative({ message: 'Value must be grater or equal to 0' })
        .refine(value => value >= lastVlidationValue, {
          message: `Miles must be greater or equal to previous miles: ${lastVlidationValue.toLocaleString('en-US')}`,
        }),
      tncMilesValue: z
        .number({ errorMap: () => ({ message: 'Required' }) })
        .nonnegative({ message: 'Value must be grater or equal to 0' }),
    })
    .refine(
      schema => {
        return schema.odometerValue - lastVlidationValue >= schema.tncMilesValue
      },
      {
        message: 'Commercial drived  miles  are more than odometer reported miles',
        path: ['tncMilesValue'],
      }
    )
}

export default ({ vehicleOdometer, userId, vehicleIndex, count, onSubmitForm }: MilesFormProps) => {
  const [formState, setFormState] = useState<MilesFormType>({
    odometerValue: vehicleOdometer.indicatedMileage,
    tncMilesValue: vehicleOdometer.tncMiles,
  })
  const [formError, setFormError] = useState<MilesFormError>({} as MilesFormError)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const validation = createValidator(vehicleOdometer.lastVlidationValue).safeParse(formState)
    if (validation.success) {
      setFormError({
        odometerValue: undefined,
        tncMilesValue: undefined,
      })
      onSubmitForm(formState)
    } else {
      const issues = validation.error.flatten().fieldErrors
      setFormError({
        odometerValue: issues.odometerValue && issues.odometerValue[0],
        tncMilesValue: issues.tncMilesValue && issues.tncMilesValue[0],
      })
    }
  }

  useEffect(() => {
    setFormState({
      odometerValue: vehicleOdometer.indicatedMileage,
      tncMilesValue: vehicleOdometer.tncMiles,
    })
  }, [vehicleOdometer])

  return (
    <>
      <div className="space-y-5">
        <div className="space-y-4">
          <div className="flex flex-row justify-between">
            <div className="font-medium	">User ID: </div>
            <div className="font-semibold text-gray-900">{userId}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="font-medium	">Uploaded on: </div>
            <div className="font-semibold text-gray-900">{vehicleOdometer.uplodedDate}</div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex flex-row justify-between">
            <div className="font-medium	">Indicated mileage: </div>
            <div className="font-semibold text-gray-900">
              {vehicleOdometer?.indicatedMileage && vehicleOdometer.indicatedMileage.toLocaleString('en-US')}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="font-medium	">
              {count > 1 ? (
                <span>
                  Vehicle {vehicleIndex + 1}/{count}:
                </span>
              ) : (
                <span>Vehicle:</span>
              )}
            </div>
            <div className="w-48 break-normal text-right font-semibold text-gray-900">{vehicleOdometer.vechile}</div>
          </div>
          <div className="flex justify-center">
            <img className="h-56 w-80  rounded-md" src={vehicleOdometer.imageUrl} />
          </div>
        </div>
      </div>
      <form className="space-y-4" id="approve-odometer" onSubmit={handleSubmit}>
        {/* Odometer value */}
        <Form.Control className=" text-base font-semibold text-gray-900">
          <Form.Label>Odometer value</Form.Label>
          <Form.Input
            className={`font-normal	${formError?.odometerValue && 'input-error'}`}
            value={formState.odometerValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormState({ ...formState, odometerValue: parseInt(e.target.value, 10) })
            }
            placeholder="Odometer Value"
            type="number"
          />
          {formError.odometerValue && (
            <Form.Error className="mt-2 text-base font-normal">{formError.odometerValue}</Form.Error>
          )}
        </Form.Control>
        {/* Commercial miles value */}
        <Form.Control className=" text-base font-semibold text-gray-900">
          <Form.Label>Commercial miles value</Form.Label>
          <Form.Input
            type="number"
            className={`font-normal ${formError?.tncMilesValue && 'input-error'}`}
            value={formState.tncMilesValue}
            placeholder="Commercial miles value"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormState({ ...formState, tncMilesValue: parseInt(e.target.value, 10) })
            }
            disabled={vehicleOdometer.transactionType === 'Initial'}
          />
          {formError.tncMilesValue && (
            <Form.Error className="mb-4 mt-2 text-base font-normal">{formError.tncMilesValue}</Form.Error>
          )}
        </Form.Control>
      </form>
    </>
  )
}
