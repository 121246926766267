import { USStateKeys } from '@drive/shared'
import { z } from 'zod'

export const ReportTypeList = ['CGI', 'Bordereaux', 'Verisk'] as const
export type ReportTypeListType = (typeof ReportTypeList)[number]

export const ReportFormObj = z
  .object({
    state: z
      .enum(USStateKeys, {
        errorMap: () => ({ message: 'Required' }),
      })
      .optional(),
    startDate: z.coerce.date({
      errorMap: () => ({ message: 'Required' }),
    }),
    endDate: z.coerce.date({ errorMap: () => ({ message: 'Required' }) }),
    reportType: z.enum(ReportTypeList, {
      errorMap: () => ({ message: 'Required' }),
    }),
  })
  .refine(
    schema => {
      return !(schema.endDate <= schema.startDate)
    },
    {
      message: 'Start date must be before end date',
      path: ['startDate'],
    }
  )
export type ReportFormType = z.infer<typeof ReportFormObj>
