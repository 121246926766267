import { HTMLAttributes, SVGAttributes, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  AlertCircle,
  ClockRefreshIcon,
  SearchIcon,
  SpeedometerIcon,
  styled,
  FileShieldIcon,
  UserPlusIcon,
  useDialog,
  EmailIcon,
  CheckIcon,
  CloseIcon,
  UploadCloudNoBorderIcon,
  ChatIcon,
} from '@skywatch/ui'
import { useSession } from '@drive/auth'
import { SelectUserApplicationsDialog } from '~/components/Dialogs'

const SelectBtn = styled.div` border border-gray-300 hover:cursor-pointer px-5 py-2 items-center`

export const debounce = (func: (value: any) => void, time: number) => {
  let timer: ReturnType<typeof setTimeout> | null = null
  return (...args: any) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(async () => {
      timer = null
      await func(args[0])
    }, time)
  }
}

type DashboardHeaderProps = {
  onSearchFiledChange: (value: string) => void
}

type Grid = {
  key: string
  title: string
  tab: string
  button: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
}

const IconWrapper = ({ children }: HTMLAttributes<HTMLDivElement>) => (
  <div className="bg-primary-50 box-content w-fit self-center rounded-full p-2.5">
    <div className=" bg-primary-100 box-content rounded-full border-none p-3 shadow shadow-gray-200">{children}</div>
  </div>
)

export const getStatusElement = (status: string | undefined) => {
  switch (status) {
    /// Quote status
    case 'Bound':
      return (
        <div className="border-success-200 text-success-700 bg-success-50 w-fit rounded-full border px-2">{status}</div>
      )
    case 'Quoted':
      return (
        <div className="border-secondary-200 text-secondary-700 bg-secondary-50 w-fit rounded-full border px-2">
          {status}
        </div>
      )
    case 'Application':
      return <div className="w-fit rounded-full border border-gray-200 bg-gray-50 px-2 text-gray-700">Application</div>
    /// Polcy status
    case 'Active':
      return (
        <div className="border-success-200 text-success-700 bg-success-50 flex w-fit  flex-row items-center gap-1 rounded-full border px-2">
          <CheckIcon className="stroke-success-500 h-3 w-3 stroke-2" />
          {status}
        </div>
      )
    case 'Expired':
      return (
        <div className="border-error-200 text-error-700 bg-error-50 flex w-fit flex-row items-center gap-1 rounded-full border px-2">
          <AlertCircle className="stroke-error-500 h-3 w-3 stroke-2" />
          {status}
        </div>
      )
    case 'Canceled':
      return (
        <div className="flex w-fit  flex-row items-center gap-1 rounded-full border border-gray-200 bg-gray-50 px-2 text-gray-700">
          <CloseIcon className="h-3 w-3 stroke-gray-500 stroke-2" />
          {status}
        </div>
      )
    case 'Scheduled':
      return (
        <div className="border-lightBlue-200 bg-lightBlue-50 text-lightBlue-700 flex w-fit  flex-row items-center gap-1 rounded-full border px-2">
          <ClockRefreshIcon className="stroke-lightBlue-500 h-3 w-3 stroke-2" />
          {status}
        </div>
      )
    /// Odometer status
    case 'Approved':
      return (
        <div className="border-success-200 text-success-700 bg-success-50 flex w-fit  flex-row items-center gap-1 rounded-full border px-2">
          <CheckIcon className="stroke-success-500 h-3 w-3 stroke-2" />
          {status}
        </div>
      )
    case 'Pending Upload':
      return (
        <div className="border-primary-200 text-primary-700 bg-primary-50 flex w-fit flex-row items-center gap-1 rounded-full border px-2">
          <UploadCloudNoBorderIcon className="stroke-primary-500 h-3 w-3" />
          {status}
        </div>
      )
    case 'Average Miles':
      return (
        <div className="border-lightBlue-200 bg-lightBlue-50  text-lightBlue-700 flex w-fit flex-row items-center gap-1 rounded-full border px-2">
          <AlertCircle className="stroke-lightBlue-500 h-3 w-3 stroke-2" />
          {status}
        </div>
      )
    case 'Pending Approval':
      return (
        <div className="border-error-200 bg-error-50 text-error-700 flex w-fit  flex-row items-center gap-1 rounded-full border px-2">
          <ChatIcon className="stroke-error-500 h-3 w-3 stroke-1" />
          {status}
        </div>
      )
    default:
      return <div className="w-fit rounded-full border px-2">{status}</div>
  }
}

export const DashboardHeader = ({ onSearchFiledChange }: DashboardHeaderProps) => {
  const [inputValue, setInputValue] = useState('')
  const { tab } = useParams()
  const { setOpen, Dialog } = useDialog()
  const [Loader, setLoader] = useState(false)

  const navigate = useNavigate()
  const { state: user } = useSession()

  const filter = async (value: string) => {
    onSearchFiledChange(value)
  }

  const grids: Grid[] = [
    {
      key: 'recentSubmissions',
      title: 'Recent Submissions',
      tab: 'quotes',
      button: (props: SVGAttributes<SVGSVGElement>) => (
        <SelectBtn onClick={() => navigate('/dashboard/quotes')} {...props}>
          <ClockRefreshIcon className={`${tab === 'quotes' ? 'stroke-primary-500' : 'stroke-black'} stroke-2`} />
          <p className="content-center">Recent Submissions</p>
        </SelectBtn>
      ),
    },
    {
      key: 'policiesManagement',
      title: 'Policies Management',
      tab: 'policies',
      button: (props: SVGAttributes<SVGSVGElement>) => (
        <SelectBtn onClick={() => navigate('/dashboard/policies')} {...props}>
          <FileShieldIcon className={`${tab === 'policies' ? 'stroke-primary-500' : 'stroke-black'} stroke-2`} />
          <p className="content-center">Policies Management</p>
        </SelectBtn>
      ),
    },
    {
      key: 'odometersMonitoring',
      title: 'Odometers Monitoring',
      tab: 'odometers',
      button: (props: SVGAttributes<SVGSVGElement>) => (
        <SelectBtn onClick={() => navigate('/dashboard/odometers')} {...props}>
          <SpeedometerIcon className={`${tab === 'odometers' ? 'stroke-primary-500' : 'stroke-black'} stroke-2`} />
          <p className="content-center">Odometers Monitoring</p>
        </SelectBtn>
      ),
    },
  ].filter(grid => user?.permissions.includes(grid.key))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandler = useCallback(debounce(filter, 500), [])

  const handleFiltering: React.ChangeEventHandler<HTMLInputElement> = e => {
    const newVal = e.target.value
    setInputValue(newVal)
    if (newVal.length < 3) {
      debounceHandler('')
      return
    }
    debounceHandler(newVal)
  }
  return (
    <div className="mb-8 mt-12 flex flex-col gap-8">
      <div className="flex flex-row justify-between text-sm">
        <div className="justify-left flex rounded-s-3xl bg-white text-center text-sm text-gray-700">
          {grids.map((grid, index) => (
            <grid.button
              className={`flex gap-2
              ${index === 0 ? 'rounded-l-xl border-r-0' : ''}
              ${index === grids.length - 1 ? 'rounded-r-xl border-l-0' : ''}
              ${tab === grid.tab && 'bg-primary-50 text-primary-500'}`}
              key={grid.key}
            />
          ))}
        </div>
        <div className="content-center">
          <Button
            className="flex flex-row "
            buttonType={'gray'}
            variant={'outline'}
            size={'small'}
            onClick={() => setOpen(true)}
          >
            <div>
              <UserPlusIcon stroke="black" />{' '}
            </div>
            <div className="font-semibold">Continue a Quote</div>
          </Button>
        </div>
      </div>
      <div className="flex flex-row space-x-3 text-sm ">
        <div className=" relative flex items-center">
          <div className="pointer-events-auto absolute pl-2">
            <SearchIcon stroke="#667085" />
          </div>
          <input
            value={inputValue}
            onChange={handleFiltering}
            type="text"
            className="border-1 h-10 w-80 gap-1.5 rounded-xl border-gray-300  pl-9"
            placeholder="Email / Phone number / Name"
          />
        </div>
      </div>
      <Dialog>
        <div className="flex justify-between">
          <div className="relative mb-2">
            <IconWrapper className="absolute">
              <EmailIcon className="stroke-primary-500 stroke-2" />
            </IconWrapper>
          </div>
          <div>
            <button onClick={() => setOpen(false)}>
              <CloseIcon className="stroke-gray-500 stroke-2" />
            </button>
          </div>
        </div>
        {/* <SelectUserApplicationsDialog
          title="Quote a Customer"
          url="/insurance"
          onLoading={setLoader}
          loading={Loader}
        /> */}
        <div className="mt-3 grid grid-cols-6 gap-3">
          <Button buttonType={'gray'} variant={'outline'} className="col-span-3" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button buttonType={'primary'} variant={'standard'} className="col-span-3" type="submit" form="modal">
            Continue
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
