import z from 'zod'

const UploadOdometer = z.object({
  vehicleId: z.string().min(1, { message: 'Required' }),
  miles: z.number().min(0, 'Miles must be greater or equal to 0').int('Miles must be an integer'),
  file: z.any().refine(files => {
    return files[0]?.size > 0
  }, `File is required`),
})
export type UploadOdometerType = z.infer<typeof UploadOdometer>

export default UploadOdometer
