import { Collection, CreateThunksReturn, createCollection, getToken, initCollection } from '@skywatch/core'
import { GetOdometersGridQueryRequest, Odometer } from './types'
import { getOdometersGridDataEndpoint } from '~/api'

const fetchOdometersDataGrid = async (odometerGridQueryRequest: GetOdometersGridQueryRequest) => {
  try {
    const { data } = await getOdometersGridDataEndpoint(null, { queryParams: odometerGridQueryRequest })
    const objects = data.objects.map(odometer => Odometer.fromApi(odometer))
    return {
      objects,
      meta: data.meta,
    }
  } catch (e) {
    throw new Error('temp error')
  }
}

export default createCollection({
  name: 'odometers',
  initialState: initCollection<Collection<Odometer>>(),
  asyncMethods: (thunks: CreateThunksReturn<Odometer>) => ({
    fetchOdometersDataGrid: thunks.upsertMany(fetchOdometersDataGrid),
  }),
})
