import { Collection, createCollection, initCollection, CreateThunksReturn } from '@skywatch/core'
import { GetPoliciesGridQueryRequest, Policy } from './types'
import { getPoliciesGridDataEndpoint } from '~/api'

const fetchPoliciesDataGrid = async (policiyGridQueryRequest: GetPoliciesGridQueryRequest) => {
  try {
    const { data } = await getPoliciesGridDataEndpoint(null, { queryParams: policiyGridQueryRequest })
    const objects = data.objects.map(policy => Policy.fromApi(policy))
    return {
      objects,
      meta: data.meta,
    }
  } catch (e) {
    throw new Error('temp error')
  }
}

export default createCollection({
  name: 'policies',
  initialState: initCollection<Collection<Policy>>(),
  asyncMethods: (thunks: CreateThunksReturn<Policy>) => ({
    fetchPoliciesDataGrid: thunks.upsertMany(fetchPoliciesDataGrid),
  }),
})
