import { ArrowLeftIcon, Button } from '@skywatch/ui'

type PaginationProps = {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
}

export const Pagination = ({ totalPages, onPageChange, currentPage }: PaginationProps) => {
  const displayPages = () => {
    if (totalPages <= 6) {
      return Array.from({ length: totalPages }, (_, index) => index + 1)
    } else {
      if (currentPage <= 3) {
        return [1, 2, 3, '...', totalPages - 1, totalPages]
      } else if (currentPage >= totalPages - 2) {
        return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages]
      } else {
        return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages]
      }
    }
  }
  return (
    <div className="flex flex-row items-center justify-between	rounded-md rounded-tl-none  rounded-tr-none border-2 border-t-0 border-gray-200 px-8 py-4 text-xs text-gray-600">
      <Button
        className="flex flex-row bg-gray-50 font-semibold text-black hover:bg-gray-200 hover:text-black"
        size={'small'}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <div>
          {' '}
          <ArrowLeftIcon stroke="black" />
        </div>
        <div>Previous</div>
      </Button>
      <div className="space-x-5">
        {displayPages().map((page, index) => (
          <span
            key={index}
            className={`hover:cursor-pointer ${page === currentPage ? 'rounded-md  bg-gray-50 p-2' : ''}`}
            onClick={() => {
              if (typeof page === 'number' && page !== currentPage) {
                onPageChange(page)
              }
            }}
          >
            {page}
          </span>
        ))}
      </div>
      <Button
        className="flex flex-row bg-gray-50 font-semibold text-black hover:bg-gray-200 hover:text-black"
        size={'small'}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages || totalPages <= 1}
      >
        <div>Next</div>
        <div>
          {' '}
          <ArrowLeftIcon className="rotate-180" stroke="black" />
        </div>
      </Button>
    </div>
  )
}
