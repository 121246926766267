import { useProfile } from '@drive/auth'
import { Page } from '@skywatch/ui'
import { Header } from '~/components'

export default () => {
  const profile = useProfile()
  return (
    <Page>
      <Header />
      <div>User : {profile.state.id}</div>
      <div>Email: {profile.state.email}</div>
    </Page>
  )
}
