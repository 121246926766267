import {
  Button,
  ExpandIcon,
  OdometerIcon,
  Table,
  TableContainer,
  TableFullRow,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
} from '@skywatch/ui'
import { Pagination } from '~/components'
import { useEffect, useState } from 'react'
import { useOdometerManager } from '../hooks'
import { ApprovedOdometer } from '../store'
interface TableRow {
  uplodedDate: string
  vechile: string
  approvedMiles: number
  imageUrl: string
}

interface TableColumn {
  key: keyof TableRow
  header: JSX.Element | string
  onClickHeader?: () => void
}
export default () => {
  const columns: TableColumn[] = [
    { key: 'uplodedDate', header: 'Date Uploaded' },
    { key: 'vechile', header: 'Vehicle' },
    { key: 'approvedMiles', header: 'Approved Miles' },
    { key: 'imageUrl', header: 'Odometer Images' },
  ]
  const { state } = useOdometerManager()
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState([] as ApprovedOdometer[])
  const itemsPerPage = 5

  const [imagePopUp, setImagePopUp] = useState({
    image: '',
    isOpen: false,
  })

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }

  const setTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const data = state.approvedOdometer.slice(startIndex, endIndex)
    setData(data)
  }
  useEffect(() => {
    setTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, state.approvedOdometer])

  return (
    <div className="flex flex-col">
      <div className="mb-8 text-base font-semibold text-gray-900 ">Previous odometers</div>

      <TableContainer className="overflow-x-auto">
        <Table>
          <TableThead>
            <tr>
              {columns.map((column, index) => (
                <TableTh key={index} onClick={column.onClickHeader}>
                  {column.header}
                </TableTh>
              ))}
            </tr>
          </TableThead>
          {state.approvedOdometer.length === 0 ? (
            <TableFullRow cols={6}>
              <div className="flex w-full flex-col items-center items-center self-center text-gray-300">
                <OdometerIcon className="h-18 w-18 stroke-gray-300 stroke-1" />
                You have no previous odometers yet
              </div>
            </TableFullRow>
          ) : (
            <tbody>
              {data.map((row, i) => (
                <TableTr key={i}>
                  {columns.map((column, columnIndex) => {
                    switch (column.key) {
                      case 'imageUrl':
                        return (
                          <TableTd key={columnIndex}>
                            <div
                              className="flex flex-row items-center space-x-2 "
                              onClick={() => setImagePopUp({ image: row.imageUrl, isOpen: true })}
                            >
                              <div>
                                <img className="h-8 w-14 rounded-md " src={row.imageUrl} />{' '}
                              </div>
                              <ExpandIcon stroke="#101828" strokeWidth="1.3" />
                            </div>
                          </TableTd>
                        )
                      case 'approvedMiles':
                        return <TableTd key={columnIndex}>{row[column.key].toLocaleString('en-US')}</TableTd>

                      default:
                        return <TableTd key={columnIndex}>{row[column.key]}</TableTd>
                    }
                  })}
                </TableTr>
              ))}
            </tbody>
          )}
        </Table>
      </TableContainer>
      {Math.ceil(state.approvedOdometer.length / itemsPerPage) > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={Math.ceil(state.approvedOdometer.length / itemsPerPage)}
        />
      )}
      {imagePopUp.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center rounded bg-white p-8 shadow-lg">
            <img className="h-full w-full rounded-md" src={imagePopUp.image} />
            <Button
              buttonType={'gray'}
              variant={'outline'}
              className="mt-3"
              onClick={() => setImagePopUp({ image: '', isOpen: false })}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
