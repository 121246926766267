import { useProfile, useSession } from '@drive/auth'
import { Header, PageTitle, Wait, useErrorModal, useFileDownload } from '@drive/shared'
import { CheckCircleIcon, CsvFileIcon, DownloadIcon, Page } from '@skywatch/ui'
import { useNavigate } from 'react-router-dom'
import ReportForm from './ReportForm'
import { ReportFormType } from './types'
import { useState } from 'react'
import { generateReportEndpoint } from '~/api/reports'

type ReportFile = {
  file: any
  fileName: string
}

export default () => {
  const navigate = useNavigate()
  const session = useSession()
  const { state: profile } = useProfile()
  const onClickBack = async () => {
    navigate('/')
  }
  const [wait, setWait] = useState(false)
  const downloadFile = useFileDownload()
  const { ErrorModal, setOpen, open } = useErrorModal()
  const [file, setFile] = useState<ReportFile | null>()
  const [message, setMessage] = useState<string | null>()

  const onSubmitForm = async (data: ReportFormType) => {
    setWait(true)
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pdfResponse: any = await generateReportEndpoint(data, { pathParams: {} })

      if (pdfResponse) {
        if (pdfResponse.data.size) {
          setFile({
            file: pdfResponse.data,
            fileName: `${data.startDate.toLocaleDateString('en-US')}-${data.endDate.toLocaleDateString('en-US')}_${
              data.reportType
            }${data.state ? `_${data.state}` : ''}_report.csv`,
          })
          setMessage(null)
        } else {
          setMessage('Report genrated successfully and soon will be upload to the drive.')
          setFile(null)
        }
      }
    } catch (error) {
      setOpen(true)
    } finally {
      setWait(false)
    }
  }

  const onDownloadClick = () => {
    if (file) {
      setWait(true)
      downloadFile(file?.file, file?.fileName)
      setWait(false)
    }
  }

  return (
    <Page className="font-poppins flex h-screen touch-none flex-col  overflow-y-auto bg-[#F2F2F2]">
      <Header />
      <main className="container flex h-[calc(100vh-60px)] flex-col overflow-auto">
        <PageTitle isAdmin={session.isAdmin} />
        <div className=" mx-auto mb-4 flex w-full flex-col rounded-md border border-gray-200 bg-white pb-6 shadow-sm md:w-4/6">
          <ReportForm onSubmit={onSubmitForm} />

          {file && (
            <div className="m-6 flex w-fit flex-row items-center space-x-4 rounded-md border border-gray-200 bg-white px-3 py-4">
              <CsvFileIcon /> <div className="grow">{file?.fileName}</div>
              <div
                className="box-content w-fit cursor-pointer  rounded-full  bg-gray-100 p-3 hover:bg-gray-50 "
                onClick={onDownloadClick}
              >
                <DownloadIcon className="stroke-gray-700" />
              </div>
            </div>
          )}
          {message && (
            <div className="m-6 flex flex-row">
              <CheckCircleIcon className="stroke-success-500 mr-3" /> {message}
            </div>
          )}
        </div>
      </main>

      <Wait visible={wait} />
      <ErrorModal errorText="Failed to create report" />
    </Page>
  )
}
