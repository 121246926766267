import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronDownIcon, Table, TableContainer, TableTd, TableTh, TableThead, TableTr } from '@skywatch/ui'
import { useCollection } from '@skywatch/core'
import { Wait } from '@drive/shared'
import { useProfile } from '@drive/auth'
import policiesCollection from './store/policies.collection'
import { GetPoliciesGridQueryRequest } from './store/types'
import { Pagination } from '~/components'
import { DashboardHeader, getStatusElement } from './components'

interface TableRow {
  effectiveDate: string
  expireDate: string
  id: string
  policyNumber: string
  policyInfo: string
  policyStatus: string
  paymentRecurrence: string
  fullName: string
  userEmail: string
  state: string
  phoneNumber: string
}

interface TableColumn {
  key: keyof TableRow
  header: JSX.Element | string
  onClickHeader?: () => void
}

const ITEM_LIMIT = 10

export default () => {
  const [filtering, setFiltering] = useState<GetPoliciesGridQueryRequest>({
    limit: ITEM_LIMIT,
    page: 1,
    sortBy: '-EffectiveDate',
    searchValue: '',
  })
  const policies = useCollection(policiesCollection)
  const navigate = useNavigate()
  const profile = useProfile()

  const setSortByValue = (col: string) => {
    switch (col) {
      case 'EffectiveDate': {
        setFiltering(prev => ({
          ...prev,
          sortBy: prev.sortBy === 'EffectiveDate' ? '-EffectiveDate' : 'EffectiveDate',
        }))
        return
      }
      default: {
        setFiltering(prev => ({
          ...prev,
          sortBy: prev.sortBy === 'ExpireDate' ? '-ExpireDate' : 'ExpireDate',
        }))
      }
    }
  }
  const columns: TableColumn[] = [
    {
      key: 'effectiveDate',
      header: (
        <div className="flex flex-row items-center" onClick={() => setSortByValue('EffectiveDate')}>
          <div>Effective Date</div>
          <a href="#" className="ml-2 rounded-xl bg-gray-100 text-gray-400  hover:bg-gray-200">
            <ChevronDownIcon className={`${filtering.sortBy === 'EffectiveDate' ? 'rotate-180' : ''}`} stroke="black" />{' '}
          </a>
        </div>
      ),
    },
    {
      key: 'expireDate',
      header: (
        <div className="flex flex-row items-center" onClick={() => setSortByValue('ExpireDate')}>
          <div>Expire Date</div>
          <a href="#" className="ml-2 rounded-xl bg-gray-100 text-gray-400  hover:bg-gray-200">
            <ChevronDownIcon className={`${filtering.sortBy === 'ExpireDate' ? 'rotate-180' : ''}`} stroke="black" />{' '}
          </a>
        </div>
      ),
    },
    { key: 'fullName', header: 'Full Name' },
    { key: 'policyNumber', header: 'Policy Numnber' },
    { key: 'policyStatus', header: 'Policy Status' },
    { key: 'paymentRecurrence', header: 'Payment Plan' },
    { key: 'userEmail', header: 'Email' },
    { key: 'phoneNumber', header: 'Phone Number' },
    { key: 'state', header: 'State' },
    { key: 'policyInfo', header: '' },
  ]

  const getData = async () => {
    policies.reset()
    await policies.fetchPoliciesDataGrid(filtering)
  }
  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering])

  const viewPolicyOnClick = async (userId: number, email: string) => {
    await profile.getUser(userId)
    navigate(`/user/${userId}/policy`)
  }

  const onPageChange = (page: number) => {
    setFiltering(prev => ({ ...prev, page: page }))
  }
  const onSerachFilter = (value: string) => {
    setFiltering(prev => ({ ...prev, searchValue: value }))
  }

  return (
    <>
      <DashboardHeader onSearchFiledChange={onSerachFilter} />
      <TableContainer className="overflow-x-auto">
        <Table>
          <TableThead>
            <tr>
              {columns.map((column, index) => (
                <TableTh key={index} onClick={column.onClickHeader}>
                  {column.header}
                </TableTh>
              ))}
            </tr>
          </TableThead>
          <tbody>
            {policies.items.map(policy => (
              <TableTr key={policy.id}>
                {columns.map((column, columnIndex) => (
                  <TableTd key={columnIndex}>
                    {column.key === 'policyInfo' ? (
                      <button
                        className="text-primary-600 underline underline-offset-1"
                        onClick={() => viewPolicyOnClick(policy.userId, policy.userEmail)}
                      >
                        View Policy
                      </button>
                    ) : column.key === 'policyStatus' ? (
                      getStatusElement(policy.policyStatus)
                    ) : (
                      policy[column.key]
                    )}
                  </TableTd>
                ))}
              </TableTr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={filtering.page}
        totalPages={Math.ceil(policies.state.meta?.total / ITEM_LIMIT)}
        onPageChange={onPageChange}
      />

      <Wait visible={policies.isBusy} />
    </>
  )
}
