import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ChevronDownIcon,
  DropdownMenu,
  LoadingIcon,
  TableContainer,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  Table,
  TableFullRow,
  ClipboardIcon,
} from '@skywatch/ui'
import { useCollection } from '@skywatch/core'
import { Pagination } from '~/components'
import { Quote, stageMenu, stateMenu, statusMenu } from './store/types'
import quotesCollection from './store/quotes.collection'
import { GetQuotesGridQueryRequest } from './store/types'
import { DashboardHeader, getStatusElement } from './components'
import { CreditCardIcon, CreditCardLockIcon } from '@drive/shared'

const ITEM_LIMIT = 10

const actions = [
  [
        { text: 'Back to Quote', icon: ClipboardIcon },
        {
          text: 'Go to Profile',
          icon: CreditCardIcon,
        },
        {
          text: 'Declinations & referrals',
          icon: CreditCardLockIcon,
        }
  ],
]

export default () => {
  const quotes = useCollection(quotesCollection)
  const [currentPage, setCurrentPage] = useState(1)
  const [filtering, setFiltering] = useState<GetQuotesGridQueryRequest>({
    limit: 10,
    page: 1,
    searchValue: '',
    status: '',
    stage: '',
    orderBy: '-RegistrationTime',
    state: '',
  })
  const navigate = useNavigate()

  const getData = async () => {
    quotes.reset()
    await quotes.fetchQuoteDataGrid(filtering)
  }

  const onSearch = (searchValue: string) => {
    setFiltering(prev => ({ ...prev, searchValue: searchValue }))
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    setFiltering(prev => ({
      ...prev,
      page: page,
    }))
  }

  const onBackToQuote = (application: { userId: number; email: string | undefined }) => {
    if (!application) return
    navigate(`/user/${application.userId.toString()}/insurance`)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering])

  const actionsOnClick = (text: string, quote: Quote) => {
    switch (text) {
      case 'Back to Quote':
        onBackToQuote({ userId: quote.userId, email: quote.email })
        return
      case 'Go to Profile':
        navigate(`/user/${quote.userId}/profile`)
        return
      case 'Declinations & referrals':
        navigate(`/user/${quote.userId}/declinations`)
        return
    }
  }

  return (
    <>
      <DashboardHeader onSearchFiledChange={onSearch} />
      <TableContainer className="overflow-x-auto">
        <Table>
          <TableThead>
            <TableTr>
              <TableTh>
                <a href="#" className="group inline-flex">
                  Registration Time
                  <span
                    onClick={() =>
                      setFiltering(prev => ({
                        ...prev,
                        orderBy: prev.orderBy === 'RegistrationTime' ? '-RegistrationTime' : 'RegistrationTime',
                      }))
                    }
                    className="ml-2 flex flex-col justify-center rounded-xl bg-gray-100 text-gray-400  hover:bg-gray-200"
                  >
                    <ChevronDownIcon
                      className={`h-5 w-5 stroke-gray-950 ${
                        filtering.orderBy === 'RegistrationTime' ? 'rotate-180' : ''
                      }`}
                      aria-hidden="true"
                    />
                  </span>
                </a>
              </TableTh>
              <TableTh>
                <a href="#" className="group inline-flex">
                  Full Name
                </a>
              </TableTh>
              <TableTh>
                <a href="#" className="group inline-flex">
                  User Email
                </a>
              </TableTh>

              <TableTh>
                <a href="#" className="group inline-flex">
                  Phone Number
                </a>
              </TableTh>

              <TableTh>
                <DropdownMenu
                  items={statusMenu}
                  onClick={text => setFiltering(prev => ({ ...prev, status: text === 'All' ? '' : text }))}
                >
                  <a href="#" className="group inline-flex items-center">
                    <div className="flex flex-col">
                      Status
                      {filtering.status && <span className="text-xs text-gray-400">({filtering.status})</span>}
                    </div>
                    <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 hover:bg-gray-200">
                      <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
                    </span>
                  </a>
                </DropdownMenu>
              </TableTh>
              <TableTh>
                <DropdownMenu
                  items={stageMenu}
                  onClick={text =>
                    setFiltering(prev => ({
                      ...prev,
                      stage: text === 'All' ? '' : text,
                    }))
                  }
                >
                  <a href="#" className="group inline-flex items-center">
                    <div className="flex flex-col">
                      Stage
                      {filtering.stage && <span className="text-xs text-gray-400">({filtering.stage})</span>}
                    </div>
                    <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                      <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
                    </span>
                  </a>
                </DropdownMenu>
              </TableTh>
              <TableTh>
                <DropdownMenu
                  items={stateMenu}
                  onClick={text =>
                    setFiltering(prev => ({
                      ...prev,
                      state: text === 'All' ? '' : text,
                    }))
                  }
                  className="h-96 overflow-y-scroll"
                >
                  <a href="#" className="group inline-flex items-center ">
                    <div className="flex flex-col ">
                      State
                      {filtering.state && <span className="text-xs text-gray-400">({filtering.state})</span>}
                    </div>
                    <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                      <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
                    </span>
                  </a>
                </DropdownMenu>
              </TableTh>
              <TableTh></TableTh>
            </TableTr>
          </TableThead>
          <tbody className={`${quotes.isBusy ? 'relative' : ''}`}>
            {quotes.isBusy && (
              <div className="absolute mt-3 flex h-full w-full flex-row items-center justify-center bg-white/70 ">
                <LoadingIcon className="stroke-primary-600 h-16 w-16 animate-spin flex-row justify-center" />
              </div>
            )}
            {quotes.items.map(quote => (
              <TableTr key={quote.id} className="h-12">
                <TableTd>
                  {quote.creationDate?.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </TableTd>
                <TableTd>
                  {quote.holderFirstName && quote.holderLastName
                    ? `${quote.holderFirstName} ${quote.holderLastName}`
                    : ''}
                </TableTd>
                <TableTd>{quote.email}</TableTd>
                <TableTd>{quote.phone}</TableTd>
                <TableTd>{getStatusElement(quote.status)}</TableTd>
                <TableTd>{quote.stage}</TableTd>
                <TableTd>{quote.state}</TableTd>
                <tbody>
                <TableTd key={quote.id}>
                    <DropdownMenu
                            items={actions}
                            onClick={text => actionsOnClick(text, quote)}
                            className="z-10 flex w-max content-center self-center stroke-black"
                            >
                            <a href="#" className="flex flex-row items-center self-center">
                          <div className="text-primary-500 flex flex-col">Actions</div>
                        <ChevronDownIcon className="stroke-primary-500 h-5 w-5" aria-hidden="true" />
                      </a>
                    </DropdownMenu>
                  </TableTd>
                </tbody>
                {/* {quote.status !== 'Bound' && (
                  <TableTd>
                    <p
                      className="text-primary-500 underline hover:cursor-pointer"
                      onClick={() => onBackToQuote({ userId: quote.userId, email: quote.email })}
                    >
                      Back to quote
                    </p>
                  </TableTd>
                )} */}
              </TableTr>
            ))}
            {[...new Array(Math.abs(quotes.count - ITEM_LIMIT))].map((q, i) => (
              <TableFullRow className="h-12" cols={6} key={q}>
                {quotes.count === 0 && i === 0 ? (
                  <span className="flex flex-row justify-center text-lg font-medium text-gray-400">
                    No quotes found
                  </span>
                ) : undefined}
              </TableFullRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(quotes.state.meta?.total / ITEM_LIMIT)}
        onPageChange={onPageChange}
      />
    </>
  )
}
