import { z } from 'zod'

export const UserEmailSchema = z.object({
  userId: z.number(),
  email: z.string(),
})

export const UsersSchema = z.object({
  results: UserEmailSchema.array(),
})

export type UserEmail = z.infer<typeof UserEmailSchema>
