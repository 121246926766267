import { Model } from '@skywatch/core'
import { OdometerGridDataSchema, PolicyGridDataSchema, QuoteGridDataApi } from '~/models'
import { DeclinationApi, DeclinationDataSchema } from '~/models/declination'
import { ProfileApi } from '~/models/profile'

export type GetPoliciesGridQueryRequest = {
  limit: number
  page: number
  sortBy: string
  searchValue: string
}

export type GetOdometersGridQueryRequest = {
  limit: number
  page: number
  sortBy: string
  searchValue: string
}

export type SearchByType = {
  emailOrPhone: string
  stage: string
  status: string
}

export type GetQuotesGridQueryRequest = {
  limit: number
  page: number
  searchValue: string
  status: string
  stage: string
  orderBy: string
  state: string
}

export type OrderByType = 'CreationDate'

interface Policy extends Model<string> {
  effectiveDate: string
  expireDate: string
  userId: number
  policyNumber: string
  tenure: number
  policyStatus: string
  fullName: string
  userEmail: string
  state: string
  phoneNumber: string
  paymentRecurrence: string
}

const Policy = {
  fromApi: (policy: PolicyGridDataSchema) => ({
    effectiveDate: policy.effectiveDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }),
    expireDate: policy.expireDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }),
    userId: policy.userId,
    policyNumber: policy.displayPolicyNumber,
    policyStatus: policy.policyStatus,
    fullName: `${policy.firstName} ${policy.lastName}`,
    userEmail: policy.userEmail,
    state: policy.state,
    phoneNumber: policy.phoneNumber,
    id: policy.policyId,
    paymentRecurrence: policy.paymentRecurrence,
    tenure: policy.tenure,
  }),
}

const OdometerStatus = ['Pending Upload', 'Pending Approval', 'Approved', 'Average Miles', 'Default Mileage'] as const
export type OdometerStatusType = (typeof OdometerStatus)[number]

interface Odometer extends Model<string> {
  effectiveDate: string
  userId: number
  policyNumber: string
  userEmail: string
  odometerStatus: OdometerStatusType
  odometerDueDate?: string
  policyId: string
  commercialDrivenMiles: number
  isPendingApproval: boolean
}

const Odometer = {
  fromApi: (odometer: OdometerGridDataSchema) => ({
    effectiveDate: odometer.effectiveDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }),
    userId: odometer.userId,
    policyNumber: odometer.displayPolicyNumber,
    userEmail: odometer.userEmail,
    odometerStatus: odometer.odometerStatus as OdometerStatusType,
    odometerDueDate: odometer.odometerDueDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }),
    policyId: odometer.policyId,
    id: odometer.policyBreakdownTransactionId,
    commercialDrivenMiles: odometer.commercialDrivenMiles,
    isPendingApproval: odometer.isPendingApproval,
  }),
}

interface Quote extends Model<string> {
  userId: number
  email?: string
  phone?: string
  holderFirstName?: string
  holderLastName?: string
  startDate: Date
  creationDate: Date
  address?: string
  city?: string
  state?: string
  zip?: string
  status?: string
  stage?: string
}

const Quote = {
  fromApi: (quote: QuoteGridDataApi) => ({
    id: quote.quoteId,
    userId: quote.userId,
    email: quote.email,
    phone: quote.phone,
    holderFirstName: quote.holderFirstName,
    holderLastName: quote.holderLastName,
    startDate: quote.startDate,
    creationDate: quote.creationDate,
    address: quote.address,
    city: quote.address,
    state: quote.state,
    zip: quote.zip,
    status: quote.status,
    stage: quote.stage,
  }),
}

export const stateMenu = [
  [
    { text: 'All' },
    { text: 'AL' },
    { text: 'AK' },
    { text: 'AZ' },
    { text: 'AR' },
    { text: 'CA' },
    { text: 'CO' },
    { text: 'CT' },
    { text: 'DC' },
    { text: 'DE' },
    { text: 'FL' },
    { text: 'GA' },
    { text: 'HI' },
    { text: 'ID' },
    { text: 'IL' },
    { text: 'IN' },
    { text: 'IA' },
    { text: 'KS' },
    { text: 'KY' },
    { text: 'LA' },
    { text: 'ME' },
    { text: 'MD' },
    { text: 'MA' },
    { text: 'MI' },
    { text: 'MN' },
    { text: 'MS' },
    { text: 'MO' },
    { text: 'MT' },
    { text: 'NE' },
    { text: 'NV' },
    { text: 'NH' },
    { text: 'NJ' },
    { text: 'NM' },
    { text: 'NY' },
    { text: 'NC' },
    { text: 'ND' },
    { text: 'OH' },
    { text: 'OK' },
    { text: 'OR' },
    { text: 'PA' },
    { text: 'PR' },
    { text: 'RI' },
    { text: 'SC' },
    { text: 'SD' },
    { text: 'TN' },
    { text: 'TX' },
    { text: 'UT' },
    { text: 'VT' },
    { text: 'VA' },
    { text: 'WA' },
    { text: 'WV' },
    { text: 'WI' },
    { text: 'WY' },
  ],
]

export const statusMenu = [[{ text: 'All' }, { text: 'Application' }, { text: 'Quoted' }, { text: 'Bound' }]]
export const stageMenu = [
  [
    { text: 'All' },
    { text: 'Driver' },
    { text: 'Vehicle' },
    { text: 'Plans' },
    { text: 'Issuance' },
    { text: 'Checkout' },
    { text: 'Congrats' },
  ],
]

export { Policy, Odometer, Quote }
