import { z } from 'zod'

const PolicyGridDataSchema = z.object({
  effectiveDate: z.coerce.date(),
  expireDate: z.coerce.date(),
  userId: z.number(),
  displayPolicyNumber: z.string(),
  tenure: z.number(),
  policyStatus: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  userEmail: z.string(),
  state: z.string(),
  phoneNumber: z.string(),
  policyId: z.string(),
  paymentRecurrence: z.string(),
})

type PolicyGridDataSchema = z.infer<typeof PolicyGridDataSchema>
export { PolicyGridDataSchema }

export const PoliciesGridSchema = z.object({
  objects: z.array(PolicyGridDataSchema),
  meta: z.object({
    total: z.number(),
    offset: z.number(),
    limit: z.number(),
  }),
})

export type PoliciesGridApi = z.infer<typeof PoliciesGridSchema>
