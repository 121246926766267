import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Page } from '@skywatch/ui'
import { PageTitle, Wait } from '@drive/shared'
import { useSession, useProfile } from '@drive/auth'
import { Header } from '~/components'
import { OdometerApproval, PreviousOdometers } from './components'
import { useOdometerManager } from './hooks'
import { OdometerToValidateRequest } from './store'
import { Provider } from './store/context'

const OdometerPage = () => {
  const { policyId } = useParams()
  const session = useSession()
  const { state, fetchPolicyOdometerData, validateOdometers, setStoreDefaultValue } = useOdometerManager()

  const { state: user } = useProfile()
  const [loader, setLoader] = useState(false)

  const getData = async (policyId: string) => {
    await fetchPolicyOdometerData(policyId)
  }
  useEffect(() => {
    if (policyId !== undefined) {
      getData(policyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOdometersApproved = async (validateOdometerRequest: OdometerToValidateRequest) => {
    if (policyId !== undefined) {
      setLoader(true)
      await validateOdometers(policyId, validateOdometerRequest)
      setLoader(false)
      await getData(policyId)
    }
  }

  return (
    <Page className="font-poppins flex touch-none flex-col overflow-y-auto bg-[#F2F2F2]">
      <Header />
      <main className="container flex h-[calc(100vh-60px)] flex-col overflow-auto">
        <PageTitle isAdmin={session.isAdmin} />
        <div className=" flex shrink grow-0 flex-col  bg-white px-4 pb-6">
          <div className="flex flex-row justify-between space-x-4 px-3 py-7">
            <div className="w-4/12">
              <OdometerApproval onApprove={onOdometersApproved} />
            </div>
            <div className="w-8/12">
              <PreviousOdometers />
            </div>
          </div>
        </div>
      </main>
      <Wait visible={state.status === 'busy' || loader} />
    </Page>
  )
}

export default () => (
  <Provider>
    <OdometerPage />
  </Provider>
)
