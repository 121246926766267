import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ActiveAvatarIcon,
  Button,
  ChatCircle,
  ChevronLeftIcon,
  ChevronRightIcon,
  UploadCloudNoBorderIcon,
  useDialog,
} from '@skywatch/ui'
import { useOdometerManager } from '../hooks'
import { OdometerToValidate, OdometerToValidateRequest, PendingApprovalOdometer } from '../store'
import MilesForm, { MilesFormType } from './MilesForm'
import ManualOdometerUpload from './ManualOdometerUpload'

type ButtonsType = 'approve' | 'next' | 'previous'
type PendingApprovalOdometerProps = {
  onApprove: (validateOdometerRequest: OdometerToValidateRequest) => void
}

export default ({ onApprove }: PendingApprovalOdometerProps) => {
  const {
    state: { pendingApprovalOdometer, odometerToUpload, status, userId, email, state, phoneNumber },
    setVehiclePenddingApproval,
  } = useOdometerManager()
  const { policyId } = useParams()
  const [currentVehicleIndex, setCurrentVehicleIndex] = useState<number>(0)
  const numberOfVehiclePendingApproval = pendingApprovalOdometer.length
  const [buttonClicked, setButtonClicked] = useState<ButtonsType>()
  const { setOpen, Dialog } = useDialog()
  const [uploadOdomerPolicyId, setUploadOdomerPolicyId] = useState('')

  const onUploadOdometer = (policyId?: string) => {
    if (policyId) {
      setUploadOdomerPolicyId(policyId)
      setOpen(true)
    }
  }

  const updateMiles = (odometerValue: number, tncMiles: number) => {
    const pendingApproval = JSON.parse(JSON.stringify(pendingApprovalOdometer)) as PendingApprovalOdometer[]
    const vehicleToUpdate = pendingApproval[currentVehicleIndex]
    vehicleToUpdate.tncMiles = tncMiles
    vehicleToUpdate.indicatedMileage = odometerValue

    setVehiclePenddingApproval(pendingApproval)
    return pendingApproval
  }

  const handleSubmitForm = (data: MilesFormType) => {
    const pendingApproval = updateMiles(data.odometerValue, data.tncMilesValue)
    const validateOdometerRequest = [] as OdometerToValidate[]
    switch (buttonClicked) {
      case 'approve':
        pendingApproval.forEach(odometer => {
          validateOdometerRequest.push({
            odometerMiles: odometer.indicatedMileage,
            vehicleId: odometer.vehicleId,
            transactionItemId: odometer.transactionItemId,
            tncMiles: odometer.tncMiles,
          })
        })
        onApprove({ odometers: validateOdometerRequest })
        break
      case 'next':
        setCurrentVehicleIndex(currentVehicleIndex + 1)
        break
      case 'previous':
        setCurrentVehicleIndex(currentVehicleIndex - 1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (numberOfVehiclePendingApproval > 0 && Object.keys(pendingApprovalOdometer[currentVehicleIndex]).length == 0) {
      setCurrentVehicleIndex(0)
    }
  }, [currentVehicleIndex, numberOfVehiclePendingApproval, pendingApprovalOdometer])

  return (
    <div className="flex flex-col text-sm text-gray-600">
      <div className="mb-8 text-base font-semibold text-gray-900">Odometer Approvals</div>
      <div className="space-y-5 border-r border-gray-200 pr-5">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between border-b border-gray-200 pb-4 ">
            <div className="flex flex-row space-x-3">
              <ActiveAvatarIcon />
              <div className="flex flex-col space-y-1">
                <div className="w-32 truncate text-ellipsis font-semibold text-gray-700" title={email}>
                  {email}
                </div>
                <div>{phoneNumber}</div>
              </div>
            </div>
            <div className="flex  flex-col space-y-1 text-right">
              <div className="font-semibold text-gray-700">State:</div>
              <div>{state}</div>
            </div>
          </div>
          <Button
            onClick={() => onUploadOdometer(policyId)}
            disabled={odometerToUpload.length === 0}
            className="disable w-full p-3 font-semibold"
          >
            <UploadCloudNoBorderIcon className="h-5 w-5 stroke-white stroke-1" />
            Upload odometer
          </Button>
          <div className="mt-8 block border-b border-gray-200" />
        </div>
        {pendingApprovalOdometer.length === 0 ? (
          <div className="my-3 flex flex-row items-center space-x-3">
            <ChatCircle />
            <div className="text-primary-500 font-medium">No pending odometers</div>
          </div>
        ) : status == 'idle' && pendingApprovalOdometer[currentVehicleIndex].vechile ? (
          <div className="space-y-4">
            <MilesForm
              vehicleOdometer={pendingApprovalOdometer[currentVehicleIndex]}
              onSubmitForm={handleSubmitForm}
              userId={userId}
              count={numberOfVehiclePendingApproval}
              vehicleIndex={currentVehicleIndex}
            />
            <div className="space-y-4">
              {numberOfVehiclePendingApproval > 1 && (
                <div className="flex flex-row justify-between">
                  <button
                    onClick={() => setButtonClicked('previous')}
                    className={`text-primary-600 flex flex-row items-center space-x-1 underline underline-offset-1 ${
                      0 < currentVehicleIndex ? 'visible' : 'invisible'
                    }`}
                    type="submit"
                    form="approve-odometer"
                  >
                    <ChevronLeftIcon className="stroke-primary-500" strokeWidth={2} />
                    <div>Previous</div>
                  </button>
                  <button
                    onClick={() => setButtonClicked('next')}
                    className={`text-primary-500 flex flex-row items-center space-x-1 underline underline-offset-1 ${
                      currentVehicleIndex < numberOfVehiclePendingApproval - 1 ? 'visible' : 'invisible'
                    }`}
                    type="submit"
                    form="approve-odometer"
                  >
                    <div>Next</div> <ChevronRightIcon className="stroke-primary-500" strokeWidth={2} />
                  </button>
                </div>
              )}
              <Button
                onClick={() => setButtonClicked('approve')}
                form="approve-odometer"
                buttonType={'primary'}
                variant={'outline'}
                disabled={numberOfVehiclePendingApproval !== currentVehicleIndex + 1}
                className=" w-full p-3 font-semibold"
                type="submit"
              >
                APPROVE
              </Button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Dialog>
        <ManualOdometerUpload policyId={uploadOdomerPolicyId} onClose={(needRender: boolean) => setOpen(false)} />
      </Dialog>
    </div>
  )
}
